input {
  font-family: var(--semi-bold-font) !important;
  caret-color: #a1a9b8 !important;
}

@mixin placeholder-style {
  color: #bebebe !important;
  opacity: 1;
  font-size: var(--font-size-m);
  font-weight: 500 !important;
}

/* do not group these rules */
input::-webkit-input-placeholder {
  @include placeholder-style;
}
input:-moz-placeholder {
  /* FF 4-18 */
  @include placeholder-style;
}
input::-moz-placeholder {
  /* FF 19+ */
  @include placeholder-style;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  @include placeholder-style;
}
input::-ms-input-placeholder {
  /* Microsoft Edge */
  @include placeholder-style;
}
input::placeholder {
  /* modern browser */
  @include placeholder-style;
}

// ---- INPUT-Autocomplete -----
/* Remove input background color and reset text color when autocomplete*/
input.ant-input:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
.ant-input-affix-wrapper > input.ant-input:focus {
  -webkit-text-fill-color: #fbfbfb;
  -webkit-box-shadow: 0 0 0px 1000px #21222d inset !important;
}

input:-webkit-autofill {
  -webkit-border-start-color: #333642 !important;
  -webkit-border-end-color: #333642 !important;
  -webkit-border-before-color: #333642 !important;
  -webkit-border-after-color: #333642 !important;
}

// ---- ANT-FORM -----
.ant-form-item {
  position: relative;

  &-label {
    padding: 0 0 0 12px !important;
    display: flex;
    align-items: center;
    height: 40px; // Form Label and Input must have same value of height
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
    z-index: 2;

    label {
      color: #a1a9b8 !important;
      font: normal normal 400 13px var(--default-font) !important;
    }
  }

  &:has(.ant-switch) {
    max-height: 2.5rem;
    border: 0.063rem solid #323449;
    border-radius: 0.375rem;
    gap: 0.5rem;
    padding: 0.625rem 1rem 0.625rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #21222d !important;
  }

  &:has(.ant-input:focus),
  &:has(.ant-input:not(:placeholder-shown)),
  &:has(.ant-input-number:focus-within),
  &:has(.ant-input-number-input-wrap > input:not(:placeholder-shown)),
  &:has(.ant-select-selection-search-input:focus),
  &:has(.ant-select-selection-item),
  &:has(.ant-picker-input > input:not(:placeholder-shown)),
  &:has(.ant-picker-focused),
  &:has(.ant-picker-status-success) {
    .ant-form-item-label {
      opacity: 1;
      transform: scale(0.75) translateY(14%) translateX(14px);
      -webkit-transform-origin-x: 0;
      height: auto;
      padding: 0 2px !important;
      text-align: left;

      > label {
        opacity: 0.7;
        height: auto;
      }
    }
  }

  /* For IE Browsers*/
  &:has(.ant-input:focus),
  &:has(.ant-input:not(:-ms-input-placeholder)),
  &:has(.ant-input-number:focus),
  &:has(.ant-input-number:not(:-ms-input-placeholder)) {
    .ant-form-item-label {
      opacity: 1;
      transform: scale(0.75) translateY(-18%) translateX(-30px);
    }
  }

  &-explain-error {
    font-weight: 400 !important;
    font-size: 11px !important;
  }
}

.sub-role-form-item {
  .ant-form-item-label {
    height: 3.4rem; // Form Label and Multiple Select must have same value of height
  }
}

// ---- ANT-INPUT -----

.ant-input {
  color: #fbfbfb !important;
  font: normal normal 300 var(--font-size-s) var(--default-font) !important;
  height: 40px !important; // Form Label and Input must have same value of height
  padding-top: 0.8rem !important;

  &-outlined {
    background-color: #21222d !important;
    border-color: #868fa029 !important;

    &:hover,
    &:focus-within {
      border-color: #a1a9b8 !important;
    }
  }
  &::placeholder {
    font: normal normal 400 13px var(--default-font);
    color: #a1a9b8 !important;
  }

  &-prefix,
  &-suffix {
    color: #868fa0;
    height: fit-content;
    margin-block: auto;
  }
  &-disabled {
    &.ant-input-outlined {
      border-color: #868fa029 !important;
      opacity: 0.6;
    }
    ::placeholder {
      color: #a1a9b880 !important;
    }
  }
  &-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  &-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
    border-color: #a1a9b8;
  }
  &-status-error {
    border-color: #ff6f70 !important;
  }
}

textarea.ant-input {
  height: 60px !important;
  padding-top: 1.2rem !important;
}

// ---- ANT-INPUT-NUMBER -----

.ant-input-number {
  height: 40px; // Form Label and Input Number must have same value of height
  width: 100% !important;
  padding-top: 0.8rem !important;

  &.ant-input-number-outlined {
    background-color: #21222d !important;
    border-color: #868fa029;

    &:hover,
    &:focus-within {
      border-color: #a1a9b8 !important;
    }
  }

  .ant-input-number-input {
    padding: 0px 11px !important;
  }

  .ant-input-number-handler-wrap {
    background-color: #21222d !important;
    transition: all 0.3s !important;
  }

  .ant-input-number-handler:hover {
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      color: #fbfbfb !important;
    }
  }

  .ant-input-number-status-error {
    border-color: #ff6f70 !important;
  }
}

.ant-input-number-outlined.ant-input-number-disabled {
  opacity: 0.6;
  color: #fbfbfb;
  border: none;
}

// ---- ANT-SELECT ----
.ant-select {
  height: 2.5rem;

  &-single .ant-select-selection-item {
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-selector {
    background-color: var(--base-color) !important;
    border: 1px solid var(--input-border-color) !important;
    color: #a1a9b8 !important;

    &:hover {
      border-color: var(--form-font-color) !important;
    }
  }
  &-selection-search-input {
    margin-top: 0.4rem !important;
  }
  &-selection-item {
    margin-top: 0.8rem !important;
    color: #fbfbfb;
  }
  &-selection-placeholder {
    color: var(--form-font-color) !important;
  }
  &-open {
    .ant-select-selector {
      border: 1px solid #868fa052 !important;
    }
  }
  &-disabled {
    opacity: 0.6;

    .ant-select-selector {
      border: 1px solid var(--input-border-color) !important;
    }
  }
  &-status-error {
    .ant-select-selector {
      border-color: #ff6f70 !important;
    }
  }
  &.ant-select-multiple {
    height: 3.4rem !important; // Form Label and Multiple Select must have same value of height

    .ant-select-selection-overflow {
      margin-top: 0.2rem;
      margin-left: 0.5rem;
    }

    .ant-select-selection-item {
      background-color: #323449 !important;

      .ant-select-selection-item-content,
      .ant-select-selection-item-remove {
        color: #a0acd6;
      }
    }
  }
}

// ---- ANT-SWITCH ----
.ant-switch {
  width: 2.75rem;
  height: 1.375rem;

  .ant-switch-handle {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1rem;
    color: #ffffff;
  }

  .ant-switch-inner {
    background-color: #3c4657;
  }

  &.ant-switch-checked {
    .ant-switch-handle {
      inset-inline-start: calc(100% - 1.25rem);
    }
    .ant-switch-inner {
      background-color: #77c3b9;
    }
  }
}

// ---- ANT-CHECKBOX ----
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.ant-checkbox-wrapper {
  font: normal normal 500 13px var(--default-font);
}

.ant-checkbox-inner {
  border-color: #a1a9b8;
  background-color: transparent !important;

  &::after {
    border-color: #21222d !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #a9dfd8 !important;
  border-color: #a9dfd8 !important;
}

// ---- ANT-BADGE ----
.ant-badge {
  &-status-dot {
    border-radius: 2px !important;
  }
  &-status-text {
    color: var(--default-font-color) !important;
  }
}

// ---- ANT-TAG ----

.ant-tag {
  cursor: default;
  font: normal normal 500 13px var(--default-font);
  border: none;

  &.header-counting-tag {
    padding: 8px 12px;
    color: #fbfbfb;
    background-color: #c06abc;
    border-radius: 7px;
  }

  &.status-tag {
    padding: 4px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 0.2rem;
    background-color: #323741;
    color: #a1a9b8;

    &.active {
      color: var(--active-font-color) !important;
      background-color: var(--active-tag-color);

      svg {
        color: var(--active-font-color) !important;
      }
    }
    &.inactive {
      color: var(--inactive-font-color) !important;
      background-color: var(--inactive-tag-color);

      svg {
        color: var(--inactive-font-color) !important;
      }
    }
    &.archived {
      color: var(--archived-font-color) !important;
      background-color: var(--inactive-tag-color);

      svg {
        color: var(--archived-font-color) !important;
      }
    }

    // work order and ciis status
    &.approved {
      color: var(--approved-font-color) !important;
      background-color: var(--approved-tag-color);

      svg {
        color: var(--approved-font-color) !important;
      }
    }
    &.converted {
      color: var(--converted-font-color) !important;
      background-color: var(--converted-tag-color);

      svg {
        color: var(--converted-font-color) !important;
      }
    }
    &.draft {
      color: var(--draft-font-color) !important;
      background-color: var(--draft-tag-color);

      svg {
        color: var(--draft-font-color) !important;
      }
    }
    &.new {
      color: var(--new-font-color) !important;
      background-color: var(--new-tag-color);

      svg {
        color: var(--new-font-color) !important;
      }
    }
    &.pending {
      color: var(--pending-font-color) !important;
      background-color: var(--pending-tag-color);

      svg {
        color: var(--pending-font-color) !important;
      }
    }
    &.rejected,
    &.rejected-ciis,
    &.rejected-wo {
      color: var(--rejected-font-color) !important;
      background-color: var(--rejected-tag-color);

      svg {
        color: var(--rejected-font-color) !important;
      }
    }
    &.reviewed {
      color: var(--reviewed-font-color) !important;
      background-color: var(--reviewed-tag-color);

      svg {
        color: var(--reviewed-font-color) !important;
      }
    }
    &.submitted {
      color: var(--submitted-font-color) !important;
      background-color: var(--submitted-tag-color);

      svg {
        color: var(--submitted-font-color) !important;
      }
    }

    // increasing & decreasing percentage in dashboard
    &.increasing {
      color: var(--increasing-font-color) !important;
      background-color: var(--increasing-tag-color);
    }
    &.decreasing {
      color: var(--decreasing-font-color) !important;
      background-color: var(--decreasing-tag-color);
    }
    &.remain {
      color: var(--remain-font-color) !important;
      background-color: var(--remain-tag-color);
    }

    // employee status (Has additional status from top)
    &.onboarding {
      color: var(--onboarding-font-color) !important;
      background-color: var(--onboarding-tag-color);

      svg {
        color: var(--onboarding-font-color) !important;
      }
    }

    // employment status (Has additional status from top)
    &.accepted {
      color: var(--accepted-font-color) !important;
      background-color: var(--accepted-tag-color);

      svg {
        color: var(--accepted-font-color) !important;
      }
    }
    &.contract-signed {
      color: var(--contract-signed-font-color) !important;
      background-color: var(--contract-signed-tag-color);

      svg {
        color: var(--contract-signed-font-color) !important;
      }
    }
    &.rl-gen {
      color: var(--rl-gen-font-color) !important;
      background-color: var(--rl-gen-tag-color);

      svg {
        color: var(--rl-gen-font-color) !important;
      }
    }
    &.rl-signed {
      color: var(--rl-signed-font-color) !important;
      background-color: var(--rl-signed-tag-color);

      svg {
        color: var(--rl-signed-font-color) !important;
      }
    }
    &.rl-emailed {
      color: var(--rl-emailed-font-color) !important;
      background-color: var(--rl-emailed-tag-color);

      svg {
        color: var(--rl-emailed-font-color) !important;
      }
    }
    &.pcec-approved {
      color: var(--pcec-approved-font-color) !important;
      background-color: var(--pcec-approved-tag-color);

      svg {
        color: var(--pcec-approved-font-color) !important;
      }
    }
    &.le-issued {
      color: var(--le-issued-font-color) !important;
      background-color: var(--le-issued-tag-color);

      svg {
        color: var(--le-issued-font-color) !important;
      }
    }
    &.hcu-emailed {
      color: var(--hcu-emailed-font-color) !important;
      background-color: var(--hcu-emailed-tag-color);

      svg {
        color: var(--hcu-emailed-font-color) !important;
      }
    }
    &.pp-received {
      color: var(--pp-received-font-color) !important;
      background-color: var(--pp-received-tag-color);

      svg {
        color: var(--pp-received-font-color) !important;
      }
    }
    &.pp-stamped {
      color: var(--pp-stamped-font-color) !important;
      background-color: var(--pp-stamped-tag-color);

      svg {
        color: var(--pp-stamped-font-color) !important;
      }
    }
    // document status
    &.generated {
      color: var(--generated-font-color) !important;
      background-color: var(--generated-tag-color);

      svg {
        color: var(--generated-font-color) !important;
      }
    }
    &.regenerated {
      color: var(--regenerated-font-color) !important;
      background-color: var(--regenerated-tag-color);

      svg {
        color: var(--regenerated-font-color) !important;
      }
    }
  }
}

// STATUS SQUARE TAG

.square-status {
  &.active {
    svg {
      color: var(--active-font-color) !important;
    }
  }
  &.inactive {
    svg {
      color: var(--inactive-font-color) !important;
    }
  }
  &.archived {
    svg {
      color: var(--archived-font-color) !important;
    }
  }

  // employee status (Has additional status from top)
  &.new {
    svg {
      color: var(--new-font-color) !important;
    }
  }
  &.onboarding {
    svg {
      color: var(--onboarding-font-color) !important;
    }
  }

  // employment status (Has additional status from top)
  &.draft {
    svg {
      color: var(--draft-font-color) !important;
    }
  }
  &.accepted {
    svg {
      color: var(--accepted-font-color) !important;
    }
  }
  &.rejected {
    svg {
      color: var(--rejected-font-color) !important;
    }
  }
  &.contract_signed {
    svg {
      color: var(--contract-signed-font-color) !important;
    }
  }
  &.rl_gen {
    svg {
      color: var(--rl-gen-font-color) !important;
    }
  }
  &.rl_signed {
    svg {
      color: var(--rl-signed-font-color) !important;
    }
  }
  &.rl_emailed {
    svg {
      color: var(--rl-emailed-font-color) !important;
    }
  }
  &.pcec_approved {
    svg {
      color: var(--pcec-approved-font-color) !important;
    }
  }
  &.le_issued {
    svg {
      color: var(--le-issued-font-color) !important;
    }
  }
  &.hcu_emailed {
    svg {
      color: var(--hcu-emailed-font-color) !important;
    }
  }
  &.pp_received {
    svg {
      color: var(--pp-received-font-color) !important;
    }
  }
  &.pp_stamped {
    svg {
      color: var(--pp-stamped-font-color) !important;
    }
  }
}

// ---- ANT-SPIN ----

.ant-spin {
  background-color: #00000080;
  max-height: unset !important;

  &-dot,
  &-text {
    color: #fbfbfb;
  }
}

// ---- ANT-CARD ----

.ant-card {
  background: #10111b;
  border: 1px solid #3e4553;
  border-radius: 12px;
}

// ---- STATUS-BADGE ----
.status-ACTIVE {
  .ant-badge-status-default {
    background-color: var(--active-dot-color) !important;
  }
}
.status-INACTIVE {
  .ant-badge-status-default {
    background-color: var(--inactive-dot-color) !important;
  }
}
.status-ARCHIVED {
  .ant-badge-status-default {
    background-color: var(--archived-dot-color) !important;
  }
}
.status-NEW {
  .ant-badge-status-default {
    background-color: var(--new-font-color) !important;
  }
}
.status-ONBOARDING {
  .ant-badge-status-default {
    background-color: var(--onboarding-font-color) !important;
  }
}

// ---- EMPLOYMENT-STATUS-BADGE ----
.status-DRAFT {
  .ant-badge-status-default {
    background-color: var(--draft-font-color) !important;
  }
}
.status-NEW {
  .ant-badge-status-default {
    background-color: var(--new-font-color) !important;
  }
}
.status-ACCEPTED {
  .ant-badge-status-default {
    background-color: var(--accepted-font-color) !important;
  }
}
.status-REJECTED {
  .ant-badge-status-default {
    background-color: var(--rejected-font-color) !important;
  }
}
.status-CONTRACT_SIGNED {
  .ant-badge-status-default {
    background-color: var(--contract-signed-font-color) !important;
  }
}
.status-RL_GEN {
  .ant-badge-status-default {
    background-color: var(--rl-gen-font-color) !important;
  }
}
.status-RL_SIGNED {
  .ant-badge-status-default {
    background-color: var(--rl-signed-font-color) !important;
  }
}
.status-RL_EMAILED {
  .ant-badge-status-default {
    background-color: var(--rl-emailed-font-color) !important;
  }
}
.status-PCEC_APPROVED {
  .ant-badge-status-default {
    background-color: var(--pcec-approved-font-color) !important;
  }
}
.status-LE_ISSUED {
  .ant-badge-status-default {
    background-color: var(--le-issued-font-color) !important;
  }
}
.status-HCU_EMAILED {
  .ant-badge-status-default {
    background-color: var(--hcu-emailed-font-color) !important;
  }
}
.status-PP_RECEIVED {
  .ant-badge-status-default {
    background-color: var(--pp-received-font-color) !important;
  }
}
.status-PP_STAMPED {
  .ant-badge-status-default {
    background-color: var(--pp-stamped-font-color) !important;
  }
}

// ---- FORM-DISPLAY ----
.field-display {
  display: flex;
  flex-direction: column;
  min-height: 50px;
  width: 100%;
  background-color: #21222d80;
  border-radius: 4px 0px 0px 4px;
  padding: 10px 16px;

  background-image: url("https://www.colorhexa.com/2f323b.png");
  background-repeat: repeat-y;
  background-size: 5px;

  &.without-title {
    min-height: 32px;
  }

  .field-title {
    font: normal normal 500 11px var(--default-font) !important;
    color: #a1a9b8;
    margin-bottom: 0.1rem;
  }
  .field-detail {
    font: normal normal 500 13px var(--default-font) !important;
    color: #e5e6e6;
    white-space: pre-wrap;
    word-break: break-word;
  }
  .field-tags {
    .ant-tag {
      background-color: #323449;
      color: #a0acd6;
      margin-inline-end: unset;
      padding: 4px 8px !important;
    }
  }
}

.field-display-with-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 2.625rem;
  width: 100%;
  background-color: none;
  border-radius: 0.375rem;
  padding: 0.625rem 0.75rem;
  gap: 0.5rem;
  border: 0.063rem solid #a1a9b833;

  .field-title {
    font: normal normal 400 var(--font-size-s) var(--default-font) !important;
    color: #a1a9b8;
    margin-bottom: 0;
  }

  .ant-tag {
    width: 2.063rem;
    height: 1.375rem;
    border-radius: 0.25rem;
    padding: 0.125rem 0.375rem;
    gap: 0.25rem;
    box-shadow: 0 0.063rem 0.125rem 0 #1018280d;
    font: normal normal 500 var(--font-size-xs) var(--default-font) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;

    &-yes {
      border: 0.063rem solid #204922;
      background-color: #204922;
      color: #51b968;
    }

    &-no {
      border: 0.063rem solid #323741;
      background-color: #323741;
      color: #a1a9b8;
    }
  }
}

// ---- LOG-TABLE ----
.log-table {
  .ant-table {
    background: transparent !important;
    border-radius: 0;
    font-size: 0.813rem;
  }

  .ant-table-tbody {
    .ant-table-cell {
      border: none;
      padding: 0.5rem 0.5rem 0 0;
      white-space: pre-wrap;
    }

    tr:hover > td {
      background: transparent !important;
    }

    tr > td:last-child {
      padding-right: 0;
    }

    tr:nth-child(2) > td {
      padding-top: 0 !important;
    }

    .truncate-text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }
  }

  .ant-tag {
    height: 1.375rem;
    padding: 0.125rem 0.375rem;
    border-radius: 0.25rem;
    gap: 0.25rem;
    margin-inline-end: 0;
  }
}
